<template>
  <v-container fluid style="height: calc(100vh - 48px); position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4 mb-7">
      <v-col class="d-flex">
        <h1>{{ $t(`middleware.permission.title`) }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            elevation="2"
            color="green"
            dark
            medium
            class="ml-auto"
            @click="permissionCreate()"
            v-if="mxware.can('permission-create')"
        >
          <v-icon size="28">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="mb-3" v-for="(permissions_block, permissions_block_key) in permissions">
      <v-card-title>
        {{ ucFirst(permissions_block_key) }}
        <v-btn
            style="padding: 0;"
            elevation="2"
            color="green"
            dark
            medium
            class="ml-auto"
            @click="permissionCreate(permissions_block_key)"
            v-if="mxware.can('permission-create')"
        >
          <v-icon size="18">mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="d-flex flex-wrap">

        <v-col class="mx-card-permission" md="3" lg="2" v-for="(permission) in permissions_block">
          <v-list-item three-line>

            <div class="mx-card-permission__btn" v-if="mxware.can('permission-update')">
              <v-icon size="20" color="blue-grey darken-4" @click="permissionEdit(permission)">
                mdi-pencil-outline
              </v-icon>
            </div>

            <v-list-item-content>
              <v-list-item-title>{{ permission.display_name }}</v-list-item-title>
              <v-list-item-subtitle>{{ permission.name }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ permission.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

      </v-card-text>

    </v-card>


    <v-bottom-sheet app v-model="create_sheet">
      <v-sheet class="pa-10 pb-16">
        <h2 class="mb-7 d-flex justify-space-between">
          {{ $t(`middleware.permission.form.${permission_title}`) }}
          <v-icon v-if="permission_edit && mxware.can('permission-delete')" size="28" color="red" @click="permissionDelete()">
            mdi-trash-can-outline
          </v-icon>
        </h2>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
                outlined
                hide-details
                label="Name Group"
                placeholder="z.B. benutzer"
                v-model="form.name_group"
                :disabled="name_group_disabled"
                :error="errors.name_group!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
                outlined
                hide-details
                label="Name"
                placeholder="z.B. user-create"
                v-model="form.name"
                :error="errors.name!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
                outlined
                hide-details
                label="Display name"
                placeholder="z.B. Benutzer erstellen"
                v-model="form.display_name"
                :error="errors.display_name!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
                outlined
                hide-details
                label="Description"
                placeholder="Description"
                v-model="form.description"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
                style="width: 100%; padding: 28px;"
                elevation="0"
                color="green"
                class="ml-auto"
                @click="save()"
                :loading="btn_create_loading"
                :disabled="btn_create_loading"
            >
              Speichern
            </v-btn>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
                style="width: 100%; padding: 28px;"
                elevation="0"
                color="default"
                class="ml-auto"
                @click="abort()"
            >
              Abbrechnen
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>

    </v-bottom-sheet>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      permissions: [],
      permission_title: 'permission',
      preloader: true,
      create_sheet: false,
      name_group_disabled: false,
      btn_create_loading: false,
      permission_edit: false,
      errors: {},
      form: {
        id: '',
        name: '',
        name_group: '',
        display_name: '',
        description: '',
      }
    }
  },
  watch: {
    create_sheet() {
      if (this.create_sheet === false) {
        this.btn_create_loading = false
        this.permission_edit = false
        this.errors = {}
        this.form.id = ''
        this.form.name = ''
        this.form.name_group = ''
        this.form.display_name = ''
        this.form.description = ''
      }
    }
  },
  mounted() {
    this.$store.dispatch('permissionsEmployeeLaratrust').then(() => {
      this.permissions = this.$store.getters.permissions_employee_laratrust
      this.preloader = false
    })
  },
  methods: {
    permissionCreate(group_name) {
      this.create_sheet = true
      this.permission_title = 'permission_create'

      if (group_name === undefined) {
        this.name_group_disabled = false
        return
      }

      this.name_group_disabled = true
      this.form.name_group = group_name
    },
    permissionEdit(permission) {
      this.create_sheet = true
      this.permission_title = 'permission_update'
      this.name_group_disabled = true
      this.permission_edit = true

      this.form.id = permission.id
      this.form.name = permission.name
      this.form.name_group = permission.name_group
      this.form.display_name = permission.display_name
      this.form.description = permission.description
    },
    permissionDelete(id) {
      this.$store.dispatch('permissionEmployeeDeleteLaratrust', this.form.id).then(() => {
        this.getPermissions()
      })
    },
    abort() {
      this.create_sheet = false
      this.btn_create_loading = false
      this.errors = {}
    },
    save() {
      this.btn_create_loading = true
      this.itemLowerCase()
      if (this.permission_edit) {
        this.edit()
      } else {
        this.create()
      }
    },
    create() {
      this.$store.dispatch('permissionEmployeeCreateLaratrust', this.form).then(() => {
        this.getPermissions()
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.btn_create_loading = false
            break;
        }
      })
    },
    edit() {
      this.$store.dispatch('permissionEmployeeUpdateLaratrust', this.form).then(() => {
        this.getPermissions()
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.btn_create_loading = false
            break;
        }
      })
    },
    getPermissions() {
      this.permissions = this.$store.getters.permissions_employee_laratrust
      this.create_sheet = false
      this.btn_create_loading = false
    },
    ucFirst(str) {
      if (!str) return str;
      return str[0].toUpperCase() + str.slice(1);
    },
    itemLowerCase() {
      this.form.name_group = this.form.name_group.toLowerCase()
      this.form.name = this.form.name.toLowerCase()
    }
  }
}
</script>

<style lang="scss">
.v-dialog {
  width: calc(100% - 257px);
  margin-left: 257px !important;
}

.mx-card-permission {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  transition: background 350ms;

  &__btn {
    //display: none;
    display: flex;
    position: absolute;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    top: 5px;
    right: 5px;
    background: #e5e5e5;
    transition: opacity 350ms;
    opacity: 0;
  }

  &:hover {
    background: rgba(227, 227, 227, 0.3);
    .mx-card-permission__btn {
      display: flex;
      opacity: 1;
    }
  }
}

.v-icon {
  &:focus::after {
    opacity: 0;
    background: none !important;
  }
}
</style>
